// extracted by mini-css-extract-plugin
export var authorHead__aboutAuthorDescription = "dX_Hc";
export var authorHead__aboutAuthorTitle = "dX_Hb";
export var authorHead__aboutAuthorWrapper = "dX_G9";
export var authorHead__description = "dX_G7";
export var authorHead__flexWrapper = "dX_G3";
export var authorHead__name = "dX_G5";
export var authorHead__photo = "dX_G4";
export var authorHead__position = "dX_G6";
export var authorHead__socialWrapper = "dX_G8";
export var authorHead__wrapper = "dX_G2";